// modules
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
// scripts
import {
  ProjectPic,
  //SVG_BgProjectMask,
  Typo20RTF
} from '../../'
// assets
import BGIMG_L from '../../../img/projects.bg.left.svg'
import MASK_L from '../../../img/projects.mask.left.svg'

// modules
import { Stack } from '@mui/material'
// scripts
import {
  ProjectPicMobile
  //SVG_BgProjectMask,
} from '..'
// assets

//const BLUBFOO = SVG_BgProjectMask

const { REACT_APP_BASE_URL: BASE_URL } = process.env

const ProjectInfoDesktop = ({ data }) => {
  if (!data) return null
  const { description, image } = data
  const { url } = image.data.attributes
  const rtfDescription = description
    ? description.split(/##(.*?)##/g).map((chunk, key) => {
        return (
          <span
            key={key}
            style={{
              fontSize: key % 2 === 1 ? 32 : 20,
              fontWeight: key % 2 === 1 ? 900 : 500
            }}
          >
            {chunk}
          </span>
        )
      })
    : ''

  return (
    <Grid container>
      <Grid item lg={6}>
        <ProjectPic src={`${BASE_URL}${url}`} mask={MASK_L} bgImg={BGIMG_L} />
      </Grid>
      <Grid item lg={6}>
        <Typo20RTF
          sx={{
            px: 2,
            mb: 2,
            maxWidth: 640,
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          {rtfDescription}
        </Typo20RTF>
      </Grid>
    </Grid>
  )
}

ProjectInfoDesktop.propTypes = {
  data: PropTypes.object.isRequired
}

const ProjectInfoMobile = ({ data }) => {
  const { description, image } = data
  const { url } = image.data.attributes
  const rtfDescription = description.split(/##(.*?)##/g).map((chunk, key) => {
    return (
      <span
        key={key}
        style={{
          fontSize: key % 2 === 1 ? 32 : 20,
          fontWeight: key % 2 === 1 ? 900 : 500
        }}
      >
        {chunk}
      </span>
    )
  })

  return (
    <Stack sx={{ width: 1, overflow: 'hidden' }}>
      <ProjectPicMobile
        src={`${BASE_URL}${url}`}
        mask={MASK_L}
        bgImg={BGIMG_L}
      />
      <Typo20RTF
        sx={{ px: 2, mb: 2, width: 1, display: 'flex', flexWrap: 'wrap' }}
      >
        {rtfDescription}
      </Typo20RTF>
    </Stack>
  )
}

ProjectInfoMobile.propTypes = {
  data: PropTypes.object.isRequired
}

export const ProjectInfo = ({ data }) => {
  const theme = useTheme()
  const isGtLG = useMediaQuery(theme.breakpoints.up('lg'))
  return isGtLG ? (
    <ProjectInfoDesktop data={data} />
  ) : (
    <ProjectInfoMobile data={data} />
  )
}

ProjectInfo.propTypes = {
  data: PropTypes.object.isRequired
}

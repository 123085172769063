import {Box} from '@mui/material'
import React from 'react'
import PropTypes from "prop-types";

export const ContentNarrow = (props) => {
  return (
    <Box sx={{
      maxWidth: 1000,
      mx: 'auto'
    }}>
      {props.children}
    </Box>
  )
}

ContentNarrow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

// modules
import {
  Alert,
  Button,
  Checkbox,
  FormLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// scripts
import { Typo20 } from '../..'
import { isValidEmail, isValidLength } from '../../../api'

const ContactFormDesktop = () => {
  const initialFormData = {
    name: '',
    email: '',
    company: '',
    location: '',
    telephone: '',
    use_case: 'Branche auswählen',
    message: '',
    // for news and stuff; opt in
    enableEmailCommunication: false
  }
  const [severity, setSeverity] = useState('info')
  const [result, setResult] = useState('')

  const [formData, setFormData] = useState(initialFormData)

  const handleName = (event) =>
    setFormData((prev) => ({ ...prev, name: event.target.value }))
  const handleEmail = (event) =>
    setFormData((prev) => ({ ...prev, email: event.target.value }))
  const handleCompany = (event) =>
    setFormData((prev) => ({ ...prev, company: event.target.value }))

  const handleLocation = (event) =>
    setFormData((prev) => ({ ...prev, location: event.target.value }))
  const handleTelephone = (event) =>
    setFormData((prev) => ({ ...prev, telephone: event.target.value }))
  const handleBranch = (event) =>
    setFormData((prev) => ({ ...prev, use_case: event.target.value }))
  const handleMessage = (event) =>
    setFormData((prev) => ({ ...prev, message: event.target.value }))
  const handleAgreement = (event) =>
    setFormData((prev) => ({
      ...prev,
      enableEmailCommunication: event.target.checked
    }))

  const [open, setOpen] = useState(false)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <Stack sx={{ maxWidth: 514, pl: 3 }}>
      <Stack>
        <FormLabel>Name:</FormLabel>
        <TextField
          onChange={handleName}
          placeholder={'Name*'}
          required
          value={formData.name}
        />
        <FormLabel>E-Mail:</FormLabel>
        <TextField
          onChange={handleEmail}
          placeholder={'E-Mail*'}
          required
          value={formData.email}
        />
        <FormLabel>Unternehmen:</FormLabel>
        <TextField
          onChange={handleCompany}
          placeholder={'Unternehmen'}
          value={formData.company}
        />
        <FormLabel>Standort:</FormLabel>
        <TextField
          onChange={handleLocation}
          placeholder={'Standort'}
          value={formData.location}
        />
        <FormLabel>Telefon:</FormLabel>
        <TextField
          onChange={handleTelephone}
          placeholder={'Telefon'}
          value={formData.telephone}
        />
        <FormLabel>Ich möchte KARMA für folgende Gebäude einsetzen:</FormLabel>
        <Select
          id={'my-simple-select'}
          onChange={handleBranch}
          sx={{
            color: 'black',
            mb: 2.4,
            '& #my-simple-select': { fontSize: 20, padding: '3px 15px' }
          }}
          value={formData.use_case}
        >
          <MenuItem value={'Branche auswählen'}>Branche auswählen</MenuItem>
          <MenuItem value={'Immobilieneigentümer'}>
            Immobilieneigentümer
          </MenuItem>
          <MenuItem value={'Facility Management'}>Facility Management</MenuItem>
          <MenuItem value={'Projektentwicklung'}>Projektentwicklung</MenuItem>
          <MenuItem value={'Asset- & Property-Management'}>
            Asset- & Property-Management
          </MenuItem>
          <MenuItem value={'Sonstiges'}>Sonstiges</MenuItem>
        </Select>
        <FormLabel>Ihre Nachricht an uns:</FormLabel>
        <TextField
          onChange={handleMessage}
          multiline
          required
          rows={5}
          value={formData.message}
        />

        <Typography sx={{ color: '#B1B2B2', fontSize: 16 }}>
          *Pflichtfelder
        </Typography>
      </Stack>

      <Stack direction={'row'} alignItems={'flex-start'}>
        <Checkbox
          checked={formData.enableEmailCommunication}
          onChange={handleAgreement}
          value={formData.enableEmailCommunication}
        />

        <Stack alignItems={'start'}>
          <Typo20 sx={{ my: 1 }}>
            Ich stimme zu, Informationen über KARMA, Produkte und
            Dienstleistungen per E-Mail zu erhalten. Ich verstehe, dass ich
            meine Einwilligung jederzeit widerrufen kann. Für weitere
            Informationen lesen Sie bitte unsere Datenschutzerklärung.
          </Typo20>

          <Typo20 sx={{ my: 1 }}>
            KARMA wird alle hier bereitgestellten Informationen ausschließlich
            in Übereinstimmung mit der{' '}
            <Link to={'/datenschutz'}>Datenschutzerklärung</Link> verwenden.
          </Typo20>

          <Button
            disabled={
              !isValidLength(formData.name, 2) || !isValidEmail(formData.email)
            }
            onClick={() => {
              const { REACT_APP_BASE_URL: BASE_URL } = process.env
              fetch(`${BASE_URL}/api/messageInABody`, {
                method: 'POST',
                headers: {
                  accept: 'application/json, text/plain, */*',
                  'Content-Type': 'application/json'
                },
                body: `{"name": "${formData.name}", "email": "${formData.email}", "company": "${formData.company}", "telephone": "${formData.telephone}", "message": "${formData.message}", "use_case": "${formData.use_case}", "location": "${formData.location}", "enableEmailCommunication": "${formData.enableEmailCommunication}"}`
              })
                .then(() => {
                  setFormData(initialFormData)
                  setSeverity('success')
                  setOpen(true)
                  setResult('Vielen Dank für Ihre Nachricht!')
                })
                .catch((err) => {
                  console.error('error', err)
                  setSeverity('error')
                  setOpen(true)
                  setResult('Oops! Da ist was schief gelaufen!')
                })
            }}
          >
            Absenden
          </Button>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={Boolean(open)}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={severity}
              sx={{ width: '100%' }}
            >
              {result}
            </Alert>
          </Snackbar>
        </Stack>
      </Stack>
    </Stack>
  )
}

const ContactFormMobile = () => {
  const initialFormData = {
    name: '',
    email: '',
    company: '',
    location: '',
    telephone: '',
    use_case: 'Branche auswählen',
    message: '',
    // for news and stuff; opt in
    enableEmailCommunication: false
  }
  const [severity, setSeverity] = useState('info')
  const [result, setResult] = useState('')

  const [formData, setFormData] = useState(initialFormData)

  const handleName = (event) =>
    setFormData((prev) => ({ ...prev, name: event.target.value }))
  const handleEmail = (event) =>
    setFormData((prev) => ({ ...prev, email: event.target.value }))
  const handleCompany = (event) =>
    setFormData((prev) => ({ ...prev, company: event.target.value }))

  const handleLocation = (event) =>
    setFormData((prev) => ({ ...prev, location: event.target.value }))
  const handleTelephone = (event) =>
    setFormData((prev) => ({ ...prev, telephone: event.target.value }))
  const handleBranch = (event) =>
    setFormData((prev) => ({ ...prev, use_case: event.target.value }))
  const handleMessage = (event) =>
    setFormData((prev) => ({ ...prev, message: event.target.value }))
  const handleAgreement = (event) =>
    setFormData((prev) => ({
      ...prev,
      enableEmailCommunication: event.target.checked
    }))

  const [open, setOpen] = useState(false)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <Stack sx={{ width: 1 }}>
      <Stack sx={{ px: 2 }}>
        <FormLabel>Name:</FormLabel>
        <TextField
          onChange={handleName}
          placeholder={'Name*'}
          required
          value={formData.name}
        />
        <FormLabel>E-Mail:</FormLabel>
        <TextField
          onChange={handleEmail}
          placeholder={'E-Mail*'}
          required
          value={formData.email}
        />
        <FormLabel>Unternehmen:</FormLabel>
        <TextField
          onChange={handleCompany}
          placeholder={'Unternehmen'}
          value={formData.company}
        />
        <FormLabel>Standort:</FormLabel>
        <TextField
          onChange={handleLocation}
          placeholder={'Standort'}
          value={formData.location}
        />
        <FormLabel>Telefon:</FormLabel>
        <TextField
          onChange={handleTelephone}
          placeholder={'Telefon'}
          value={formData.telephone}
        />
        <FormLabel>Ich möchte KARMA für folgende Gebäude einsetzen:</FormLabel>
        <Select
          id={'my-simple-select'}
          onChange={handleBranch}
          sx={{
            color: 'black',
            mb: 2.4,
            '& #my-simple-select': { fontSize: 20, padding: '3px 15px' }
          }}
          value={formData.use_case}
        >
          <MenuItem value={'Branche auswählen'}>Branche auswählen</MenuItem>
          <MenuItem value={'Immobilieneigentümer'}>
            Immobilieneigentümer
          </MenuItem>
          <MenuItem value={'Facility Management'}>Facility Management</MenuItem>
          <MenuItem value={'Projektentwicklung'}>Projektentwicklung</MenuItem>
          <MenuItem value={'Asset- & Property-Management'}>
            Asset- & Property-Management
          </MenuItem>
          <MenuItem value={'Sonstiges'}>Sonstiges</MenuItem>
        </Select>
        <FormLabel>Ihre Nachricht an uns:</FormLabel>
        <TextField
          onChange={handleMessage}
          multiline
          required
          rows={5}
          value={formData.message}
        />

        <Typography sx={{ color: '#B1B2B2', fontSize: 16 }}>
          *Pflichtfelder
        </Typography>
      </Stack>

      <Stack direction={'row'} alignItems={'flex-start'}>
        <Checkbox
          checked={formData.enableEmailCommunication}
          onChange={handleAgreement}
          value={formData.enableEmailCommunication}
        />

        <Stack alignItems={'start'}>
          <Typo20 sx={{ my: 1 }}>
            Ich stimme zu, Informationen über KARMA, Produkte und
            Dienstleistungen per E-Mail zu erhalten. Ich verstehe, dass ich
            meine Einwilligung jederzeit widerrufen kann. Für weitere
            Informationen lesen Sie bitte unsere Datenschutzerklärung.
          </Typo20>

          <Typo20 sx={{ my: 1 }}>
            KARMA wird alle hier bereitgestellten Informationen ausschließlich
            in Übereinstimmung mit der{' '}
            <Link to={'/datenschutz'}>Datenschutzerklärung</Link> verwenden.
          </Typo20>

          <Button
            disabled={
              !isValidLength(formData.name, 2) || !isValidEmail(formData.email)
            }
            onClick={() => {
              const { REACT_APP_BASE_URL: BASE_URL } = process.env
              fetch(`${BASE_URL}/api/messageInABody`, {
                method: 'POST',
                headers: {
                  accept: 'application/json, text/plain, */*',
                  'Content-Type': 'application/json'
                },
                body: `{"name": "${formData.name}", "email": "${formData.email}", "company": "${formData.company}", "telephone": "${formData.telephone}", "message": "${formData.message}", "use_case": "${formData.use_case}", "location": "${formData.location}", "enableEmailCommunication": "${formData.enableEmailCommunication}"}`
              })
                .then(() => {
                  setFormData(initialFormData)
                  setSeverity('success')
                  setOpen(true)
                  setResult('Vielen Dank für Ihre Nachricht!')
                })
                .catch((err) => {
                  console.error('error', err)
                  setSeverity('error')
                  setOpen(true)
                  setResult('Oops! Da ist was schief gelaufen!')
                })
            }}
          >
            Absenden
          </Button>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={Boolean(open)}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={severity}
              sx={{ width: '100%' }}
            >
              {result}
            </Alert>
          </Snackbar>
        </Stack>
      </Stack>
    </Stack>
  )
}

export const ContactForm = () => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return isGtMD ? <ContactFormDesktop /> : <ContactFormMobile />
}

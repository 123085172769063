import { Box, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { SVG_KarmaLogo } from '.'
import { useMainMenu } from '../hooks'

const Logo = ({ size, ...rest }) => (
  <Box {...rest}>
    <Link to={'/'}>
      <SVG_KarmaLogo style={{ width: size, height: size }} />
    </Link>
  </Box>
)

Logo.propTypes = {
  size: PropTypes.number.isRequired
}

const MenuLink = ({ title, url, size, ...rest }) => (
  <Link
    style={{
      color: '#000000',
      fontSize: size,
      textDecoration: 'none'
    }}
    to={url}
    {...rest}
  >
    {title}
  </Link>
)

MenuLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
}

const HeaderBody = ({ data }) => {
  const secondaryData = [data[4], data[7]]

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      sx={{
        backgroundColor: 'white',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        height: 148,
        maxWidth: 1920,
        position: 'fixed',
        px: { xs: 2, lg: 5, xl: 10 },
        top: 0,
        width: 1,
        zIndex: 999999
      }}
    >
      <Stack alignItems={'center'} direction={'row'}>
        <Logo size={100} />
      </Stack>

      <Stack
        alignItems={'top'}
        direction={'row'}
        position={'relative'}
        spacing={3}
        sx={{ mt: 3, mr: { xl: 10, lg: 5, xs: 2 }, zIndex: 11 }}
      >
        <Box
          sx={{
            position: 'absolute',
            zIndex: -1,
            top: -30,
            height: 110,
            left: -100,
            right: -60
          }}
        >
          
         <img src={`/landingpages/geg/header_bg.svg`}
               width="510"
               height="143"
               style={{
                 width: '100%',
                 height: '100%'
              }}
          />
          
        </Box>
        {secondaryData.map((item, key) => {
          const { title, url } = item.attributes

          return <MenuLink key={key} title={title} url={url} size={20} />
        })}
      </Stack>
    </Stack>
  )
}

HeaderBody.propTypes = {
  data: PropTypes.array.isRequired
}

export const HeaderLandingPage = () => {
  const { data } = useMainMenu()
  if (!data) return null

  return <HeaderBody data={data.data} />
}

import {Box, useTheme} from '@mui/material'
import React from 'react'
import PropTypes from "prop-types";

export const OverVioletGradient = (props) => {
  const theme = useTheme()
  const {primary} = theme.palette

  return (
    <Box
      sx={{
        width: '100%',
        background: `linear-gradient(to right, ${primary.dark} 0%, ${primary.main} 50%, ${primary.light} 100%)`,
        border: '0px dashed #fc3',
      }}
    >
      <code></code>

      {props.children}
    </Box>
  )
}

OverVioletGradient.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

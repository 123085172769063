import React from 'react'
import { PageWrapperLandingPage, SVG_Plektrum, Typo20, Typo20Bold, Typo20RTF, Typo26Bold, Typo32, Typo43 } from '../../components'
import { Box, List, ListItem, ListItemIcon, Stack, useTheme, useMediaQuery } from '@mui/material'
import { ContentNarrow } from '../../components/wrapper/ContentNarrow'
import { CenteredOverPlektrum } from '../../components/wrapper/CenteredOverPlektrum'
import { OverVioletGradient } from '../../components/wrapper/OverVioletGradient'
import { OverGray } from '../../components/wrapper/OverGray'





export const LPGebaeudeEnergieGesetz = () => {
  const theme = useTheme();
  const { primary } = theme.palette;
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));


  return (
  <PageWrapperLandingPage data={{ title: 'Gebäudeenergiegesetz' }}>
    <CenteredOverPlektrum>
    <Box sx={{ 
      position: 'relative',
      width: '100%', 
      minHeight: '800px',
      height: 'auto',
      backgroundImage: 'url("/landingpages/geg/projects.bg.right.svg")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      padding: '20px',
      paddingBottom: isSmDown ? '50px' : '100px',
      boxSizing: 'border-box',
      flexWrap: 'wrap', 
      marginTop: -2,
      zIndex: 0 ,
    }}
  >
      
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
          textAlign: 'center',
          padding: isSmDown ? '10px' : '20px',
          paddingBottom: isSmDown ? '30px' : '50px',
          boxSizing: 'border-box',
        }}
      >
          <div style={{ color: '#1B022B', marginTop: '200px', display: 'inline'}}>
              <Typo43>
                Erfüllen Ihre Gebäude die neuen Anforderungen des GEG?
              </Typo43>
          </div>

          <div style={{marginTop: '20px', display: 'inline'}}>
            <Typo20Bold>
              Wichtige Änderung im Gebäudeenergiegesetz (GEG):
            </Typo20Bold>   
          </div>   
              
          <div style={{marginTop:'20px', display: 'inline'}}>
            <Typo20 >Bis zum <Typo20Bold style={{color: '#AF2CC5', display: 'inline'}}>31.12.2024</Typo20Bold>müssen Nichtwohngebäude</Typo20>
          </div>  
             
          <div>
            <Typo20Bold style={{ color: '#AF2CC5', display: 'inline'}}> mit einer Heizung oder Klimaanlage &gt; 290 kW Nennleistung</Typo20Bold>
          </div>    
              
          <div style={{display: 'inline'}}>
              <Typo20>
              mit einer digitalen Energieüberwachungstechnik ausgestattet werden.
            </Typo20>
          </div>

          <div style={{display: 'inline'}}>
            <Typo20>
              Ansonsten drohen Sanktionen in Höhe von bis zu 
              <Typo20Bold style={{ display: 'inline', color: '#AF2CC5'}}>50.000€.</Typo20Bold>
            </Typo20>
          </div>  
      </Box>  
    </Box>
    
    </CenteredOverPlektrum>

    <OverVioletGradient>
      <Typo26Bold
        color={primary.contrastText}  
      >
        So checken Sie, ob Ihre
        Gewerbeimmobilien betroffen sind
      </Typo26Bold>

      <Typo20>
        Überprüfen Sie jeweils die Nennleistung der Heizungs- bzw.
        Klimaanlagen in all Ihren Liegenschaften. Sie finden diese Angaben z. B.
        im Gerätehandbuch oder auf dem Typenschild am Gerät.
      </Typo20>
      <Typo20>
        Wenn die Nennleistung von mindestens einer Anlage in einem Gebäude
        &gt; 290 kW beträgt, besteht dringender Handlungsbedarf, denn bis zum
        31.12.2024 müssen Sie ein System zum Monitoring der Energieverbräuche installiert
        haben.
      </Typo20>
      <Typo43>
        KARMA erfüllt alle Anforderungen - Nehmen Sie Kontakt auf
      </Typo43>
    </OverVioletGradient>

    <ContentNarrow>
      <Typo43 sx={{ textAlign: 'center' }}>
        Diese Anforderungen gelten für Ihre Energieüberwachungstechnik
      </Typo43>
      <List
        sx={{
          columns: { xs: 1, md: 2 },
          breakInside: 'avoid-column',
          lineHeight: 0.5
        }}
      >
       
       <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>

          <ListItemIcon sx={{ minWidth: 36 }}>
            <SVG_Plektrum />
          </ListItemIcon>

          <Box>

            <Typo20Bold sx={{ color: primary.main, pr: .3, display: 'inline' }}>
              Kontinuierliche Überwachung, Protokollierung und Analyse
            </Typo20Bold>

            <Typo20 sx={{ display: 'inline' }}>
              der Verbräuche aller Hauptenergieträger sowie aller gebäudetechnischen Systeme
            </Typo20>

          </Box>

        </ListItem>

        <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>

          <ListItemIcon sx={{ minWidth: 36 }}>
            <SVG_Plektrum />
          </ListItemIcon>

          <Box>

            <Typo20Bold sx={{ color: primary.main, pr: .3, display: 'inline' }}>
              Definition von Anforderungswerten
            </Typo20Bold>

            <Typo20 sx={{ display: 'inline' }}>
              hinsichtlich der Energieeffizienz des Gebäudes
            </Typo20>

          </Box>

        </ListItem>

        <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>

          <ListItemIcon sx={{ minWidth: 36 }}>
            <SVG_Plektrum />
          </ListItemIcon>

          <Box>

            <Typo20Bold sx={{ color: primary.main, pr: .3, display: 'inline' }}>
              Benennung einer Kontaktperson 
            </Typo20Bold>

            <Typo20 sx={{ display: 'inline' }}>
              für das Gebäude-Energiemanagement um Informationen zu Verbesserungspotenzialen zu bündeln
            </Typo20>

          </Box>

        </ListItem>

        <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>

          <ListItemIcon sx={{ minWidth: 36 }}>
            <SVG_Plektrum />
          </ListItemIcon>

          <Box>

            <Typo20Bold sx={{ color: primary.main, pr: .3, display: 'inline' }}>
              Datenerhebung und -zugang
            </Typo20Bold>

            <Typo20 sx={{ display: 'inline' }}>
              über eine gängige und frei konfigurierbare Schnittstelle für eine firmen- und herstellerunabhängige Auswertung
            </Typo20>

          </Box>

        </ListItem>

        <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>

          <ListItemIcon sx={{ minWidth: 36 }}>
            <SVG_Plektrum />
          </ListItemIcon>

          <Box>

            <Typo20Bold sx={{ color: primary.main, pr: .3, display: 'inline' }}>
              Benachrichtigung bei Effizienzverlusten
            </Typo20Bold>

            <Typo20 sx={{ display: 'inline' }}>
              von gebäudetechnischen Systemen
            </Typo20>
          </Box>

        </ListItem>
      </List>
    </ContentNarrow>

    <OverGray>
      <ContentNarrow>
        <Typo43 sx={{ textAlign: 'center'}}>
          <Box sx={{ fontSize: '49px', paddingBottom: '3%', paddingLeft: '9%'}}>
          Erfüllen Sie die Anforderungen sicher und effizienter – mit KARMA
          </Box>
        </Typo43>

        <Stack direction={{xs: 'column', md: 'row'}} alignItems={'center'} justifyContent={'space-around'} sx={{gap: '3%'}}>
          <Box>
            <img src="/landingpages/geg/anforderungen.webp" alt="Anforderungen"
                 style={{ 
                   maxHeight: 240 
                  }}
            />
          </Box>
          <Box>
            <Box style={{ paddingLeft: '4%' }}><Typo26Bold>KARMA</Typo26Bold></Box>
            <List>
              <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <SVG_Plektrum />
                </ListItemIcon>

                <Box>
                  <Typo20>
                    erfüllt alle Anforderungen
                  </Typo20>
                </Box>
              </ListItem>
              <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <SVG_Plektrum />
                </ListItemIcon>

                <Box>
                  <Typo20>
                    bestätigt dem Kunden schriftlich die
                    gesetzeskonforme Erfüllung
                  </Typo20>
                </Box>
              </ListItem>
              <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <SVG_Plektrum />
                </ListItemIcon>

                <Box>
                  <Typo20>
                    optimiert den Betrieb der Anlagen
                  </Typo20>
                </Box>
              </ListItem>

              <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <SVG_Plektrum />
                </ListItemIcon>

                <Box>
                  <Typo20>
                    kann zu Energieeinsparungen beitragen und Energiekosten reduzieren
                  </Typo20>
                </Box>
              </ListItem>
            </List>
          </Box>
        </Stack>

        <Stack>
          <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>
                
                <Box sx={{ paddingLeft: '10%', paddingRight:'35%', marginTop: '1%', maxWidth: '100%', textAlign: 'center'}}>

                  <Typo26Bold sx={{textAlign: 'center'}}>
                    Nehmen Sie Kontakt zu uns auf und erhalten 
                    Sie unverbindlich ein Angebot inklusive
                    Prüfung der Fördermöglichkeiten.
                  </Typo26Bold>

                  <Box>
                    <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid', marginTop: '5%', marginBottom: '10%' }}>
                      
                      <ListItemIcon sx={{ minWidth: '1%', padding: '5%', marginTop: '-15%'}}>
                          <img src="/landingpages/geg/icon_email.svg" alt="Anforderungen"/>
                      </ListItemIcon> 
                      <Typo32 sx={{ color: primary.main, pr: .3, display: 'inline' }}>
                          info@karma.de
                       </Typo32>
                    </ListItem>
                    
                    <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid', marginTop: '-20%' }}>
                      <ListItemIcon sx={{ minWidth: '1%', paddingLeft: '7%', paddingRight: '7%', paddingTop: '7%' }}>
                        <img src="/landingpages/geg/icon_phone.svg" alt="Anforderungen"/>
                      </ListItemIcon>
                      <Typo32 sx={{ color: primary.main, pr: .3, display: 'inline' }}>
                        +49 7141 25878-111 
                       </Typo32>
                    </ListItem>
                  </Box>                    
                </Box>
            </ListItem>
        </Stack> 
      </ContentNarrow>
    </OverGray>
  </PageWrapperLandingPage>
  )
}

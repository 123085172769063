import { Stack, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Typo26, Typo43, Typo20RTF, Typo32 } from '..'
import { useTheme } from '@emotion/react'

export const DZ_TextBlock = ({ data, sloganWidth, ...rest }) => {
  const { description, slogan, title } = data
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return slogan === undefined || slogan === null ? (
    <Stack alignItems={'center'} sx={{ mb: 5, width: 1 }}>
      {isGtMD ? (
        <Typo43 sx={{ my: 2 }}>{title}</Typo43>
      ) : (
        <Typo32 sx={{ my: 2 }}>{title}</Typo32>
      )}

      <Typo20RTF sx={{ maxWidth: 1088, px: 1, width: 1 }}>
        {description}
      </Typo20RTF>
    </Stack>
  ) : (
    <Stack alignItems={'center'} sx={{ mb: 5 }}>
      <Typo26 sx={{ mt: 20 }}>{title}</Typo26>

      {isGtMD ? (
        <Typo43 sx={{ textAlign: 'center', maxWidth: sloganWidth, my: 2 }}>
          {slogan}
        </Typo43>
      ) : (
        <Typo32 sx={{ textAlign: 'center', maxWidth: sloganWidth, mx: 2 }}>
          {slogan}
        </Typo32>
      )}

      {description && description !== null && (
        <Typo20RTF gutterBottom {...rest}>
          {description}
        </Typo20RTF>
      )}
    </Stack>
  )
}

DZ_TextBlock.defaultProps = {
  sloganWidth: 'auto'
}

DZ_TextBlock.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    slogan: PropTypes.string,
    title: PropTypes.string
  }),
  sloganWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

import { Box, Grid, Stack, useMediaQuery, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import {
  DZ_GotoPage,
  ProjectPicMobile,
  Typo20,
  Typo26Bold,
  ProjectPic,
  ProjectText
} from '..'

import BGIMG_L from '../../img/projects.bg.left.svg'
import BGIMG_R from '../../img/projects.bg.right.svg'
import MASK_L from '../../img/projects.mask.left.svg'
import MASK_R from '../../img/projects.mask.right.svg'
//import { MaskItem } from './BigPic/MaskItem'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

const ProjectListMobile = ({ data }) => {
  return data.map((project, key) => {
    const { excerpt, image, slug, title } = project.attributes
    const { url } = image.data.attributes
    const src = `${BASE_URL}${url}`
    
  return (
    <Grid container key={key} display={'grid'} alignContent={'center'} justifyContent={'center'}>
      <Grid item>
        <Stack alignItems={'center'}>
          <Box >
            <ProjectPicMobile
              src={src}
              mask={key % 2 === 0 ? MASK_R : MASK_L}
              bgImg={key % 2 === 0 ? BGIMG_R : BGIMG_L}
            />
          </Box>  
        </Stack>
      </Grid>
      <Grid item> 
        <Stack alignItems={'center'} justifyContent={'center'} lg={12}>  
          <Box>
            <Typo20 >{title}</Typo20>
          </Box>
          <Box>
            <Typo26Bold >{excerpt}</Typo26Bold>
          </Box>
          <Box>
            <DZ_GotoPage
              href={`/projekte/${slug}`}
              title={'Zum Projekt'}
              sx={{ width: 240, mb: 3 }}
            />
          </Box>
        </Stack>
      </Grid>  
    </Grid>
  )  
})
}

ProjectListMobile.propTypes = {
  data: PropTypes.array
}

const ProjectListDesktop = ({ data }) => {
  return data.map((project, key) => {
    const { excerpt, image, slug, title } = project.attributes
    const { url } = image.data.attributes
    const src = `${BASE_URL}${url}`

    return key % 2 === 0 ? (
      <Grid container key={key}>
        <Grid
          item
          lg={6}
          sx={{ display: 'flex', justifyContent: 'right', height: 700 }}
        >
          <ProjectText excerpt={excerpt} slug={slug} title={title} />
        </Grid>
        <Grid item lg={6}>
          <ProjectPic src={src} mask={MASK_R} bgImg={BGIMG_R} />
        </Grid>
      </Grid>
    ) : (
      <Grid container key={key}>
        <Grid item lg={6}>
          <ProjectPic src={src} mask={MASK_L} bgImg={BGIMG_L} />
        </Grid>
        <Grid
          item
          lg={6}
          sx={{ display: 'flex', justifyContent: 'left', height: 700 }}
        >
          <ProjectText excerpt={excerpt} slug={slug} title={title} />
        </Grid>
      </Grid>
    )
  })
}

ProjectListDesktop.propTypes = {
  data: PropTypes.array
}

export const DZ_Projects = ({ data }) => {
  const theme = useTheme()
  const isGtLG = useMediaQuery(theme.breakpoints.up('lg'))

  return isGtLG ? (
    <ProjectListDesktop data={data} />
  ) : (
    <ProjectListMobile data={data} />
  )
}

DZ_Projects.propTypes = {
  data: PropTypes.array
}
